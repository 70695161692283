<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
  <g id="Group_289" data-name="Group 289" transform="translate(-1294 -808)">
    <circle id="Ellipse_13" data-name="Ellipse 13" cx="20" cy="20" r="20"
    transform="translate(1294 808)" :fill="darkMode ? '#fff' : '#47040f'"/>
    <path id="Icon_awesome-phone-alt" data-name="Icon awesome-phone-alt"
    d="M14.572,10.6,11.291,9.194a.7.7,0,0,0-.82.2L9.017,11.171A10.859,10.859,0,0,1,3.826,5.98L5.6,
    4.527a.7.7,0,0,0,.2-.82L4.4.425A.708.708,0,0,0,3.592.018L.545.721A.7.7,0,0,0,0,1.407,13.592,
    13.592,0,0,0,13.594,15a.7.7,0,0,0,.686-.545l.7-3.047a.712.712,0,0,0-.41-.809Z"
    transform="translate(1306.5 820.5)" :fill="!darkMode ? '#fff' : '#47040f'"/>
  </g>
</svg>
</template>

<script>
export default {
  name: 'Phone',
  props: {
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
