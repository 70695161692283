<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g id="Group_288" data-name="Group 288" transform="translate(-1294 -919)">
      <circle id="Ellipse_13" data-name="Ellipse 13" cx="20" cy="20" r="20"
        transform="translate(1294 919)" :fill="darkMode ? '#fff' : '#47040f'"/>
      <path id="Icon_awesome-fax" data-name="Icon awesome-fax"
        d="M1.875,3.75H.938A.938.938,0,0,0,0,4.688v9.375A.938.938,0,0,0,.938,15h.938a.938.938,0,0,0,
        .938-.937V4.688A.938.938,0,0,0,1.875,3.75Zm12.188.938V2.263a.938.938,0,0,
        0-.275-.663L12.462.275A.938.938,0,0,0,11.8,0H4.688A.938.938,0,0,0,3.75.938V14.063A.938.938,
        0,0,0,4.688,15h9.375A.938.938,0,0,0,15,14.063V5.625A.938.938,0,0,0,14.063,4.688ZM8.438,
        12.656a.469.469,0,0,1-.469.469H7.031a.469.469,0,0,1-.469-.469v-.937a.469.469,0,0,1,
        .469-.469h.938a.469.469,0,0,1,.469.469Zm0-3.75a.469.469,0,0,1-.469.469H7.031a.469.469,0,0,
        1-.469-.469V7.969A.469.469,0,0,1,7.031,7.5h.938a.469.469,0,0,1,.469.469Zm3.75,3.75a.469.469,
        0,0,1-.469.469h-.937a.469.469,0,0,1-.469-.469v-.937a.469.469,0,0,1,.469-.469h.938a.469.469,0
        ,0,1,.469.469Zm0-3.75a.469.469,0,0,1-.469.469h-.937a.469.469,0,0,1-.469-.469V7.969a.469.469,
        0,0,1,.469-.469h.938a.469.469,0,0,1,.469.469Zm.469-3.281h-7.5V1.406H11.25v.938a.469.469,0,0
        ,0,.469.469h.938Z" transform="translate(1306.5 931.5)"
        :fill="!darkMode ? '#fff' : '#47040f'"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Fax',
  props: {
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
