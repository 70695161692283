<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g id="Group_291" data-name="Group 291" transform="translate(-998 -919)">
      <circle id="Ellipse_13" data-name="Ellipse 13" cx="20" cy="20" r="20"
        transform="translate(998 919)" :fill="darkMode ? '#fff' : '#47040f'"/>
      <path id="Icon_material-mail" data-name="Icon material-mail"
        d="M16.5,6H4.5A1.5,1.5,0,0,0,3.008,7.5L3,16.5A1.5,1.5,0,0,0,4.5,18h12A1.5,1.5,0,0,0,
        18,16.5v-9A1.5,1.5,0,0,0,16.5,6Zm0,3-6,3.75L4.5,9V7.5l6,3.75,6-3.75Z"
        transform="translate(1007.5 927)" :fill="!darkMode ? '#fff' : '#47040f'"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Email',
  props: {
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
