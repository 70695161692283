<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
  <g id="Group_290" data-name="Group 290" transform="translate(-998 -808)">
    <circle id="Ellipse_13" data-name="Ellipse 13" cx="20" cy="20" r="20"
      transform="translate(998 808)" :fill="darkMode ? '#fff' : '#47040f'"/>
    <path id="Icon_ionic-md-pin" data-name="Icon ionic-md-pin"
      d="M12.107,2.25A5.32,5.32,0,0,0,6.75,7.5c0,3.937,5.357,9.75,5.357,9.75s5.357-5.812,
        5.357-9.75A5.32,5.32,0,0,0,12.107,2.25Zm0,7.125A1.875,1.875,0,1,1,14.02,
        7.5,1.876,1.876,0,0,1,12.107,9.375Z"
      transform="translate(1005.893 818.25)" :fill="!darkMode ? '#fff' : '#47040f'"/>
  </g>
</svg>

</template>

<script>
export default {
  name: 'Location',
  props: {
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
