<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g id="Group_292" data-name="Group 292" transform="translate(-998 -919)">
      <circle id="Ellipse_13" data-name="Ellipse 13" cx="20" cy="20" r="20"
      transform="translate(998 919)" :fill="darkMode ? '#fff' : '#47040f'"/>
      <g id="EnFbY5" transform="translate(1787.078 1029.704)">
        <g id="Group_382" data-name="Group 382" transform="translate(-776.578 -98.963)">
          <path id="Path_119" data-name="Path 119"
            d="M-763.189-87.191a6.017,6.017,0,0,1-.942,1.672,6.37,6.37,0,0,1-3.79,2.2,6.3,6.3,0,0,
            1-3.863-.482,5.881,5.881,0,0,
            1-3.1-3.179c-.023-.058-.03-.148-.091-.159-.081-.015-.1.083-.14.136a4.538,4.538,0,0,
            1-.55.635,1.31,1.31,0,0,1-.31.222c-.24.119-.371.072-.452-.186a3.358,3.358,0,0,1,
            .03-2.068,6.958,6.958,0,0,1,1.38-2.577.183.183,0,0,0,.035-.237,2.281,2.281,0,0,
            1-.184-.522.908.908,0,0,1,.093-.7,1.223,1.223,0,0,0,.15-.428.785.785,0,0,1,
            .435-.615.242.242,0,0,0,.143-.241c.022-.349.036-.7.091-1.046a4.892,4.892,0,0,1,
            1.122-2.272,5.157,5.157,0,0,1,3.325-1.867,5.238,5.238,0,0,1,5.1,2.335,5.7,5.7,0,0,1,
            .928,2.582.489.489,0,0,0,.177.322,2.338,2.338,0,0,1,.767,1.517,1.991,1.991,0,0,
            1-.188,1.1A.2.2,0,0,0-763-90.8a6.8,6.8,0,0,1,1.389,3.129,2.864,2.864,0,0,1-.122,
            1.322c-.087.283-.231.331-.494.183a2.224,2.224,0,0,
            1-.626-.583C-762.963-86.883-763.066-87.027-763.189-87.191Zm-.982-1.179a4.048,4.048,0,0,
            0-.145-1.116c-.042-.151-.089-.181-.23-.085a7.022,7.022,0,0,1-1.87.9,8.183,8.183,0,0,
            1-3.8.166c-.166-.026-.213.013-.216.178-.006.459-.023.917-.042,1.376a.819.819,0,0,
            1-.834.841,3.086,3.086,0,0,1-1.24-.176,1.141,1.141,0,0,1-.822-1.283,11.2,11.2,0,0,1,
            .139-1.405.522.522,0,0,0-.34-.667c-.161-.076-.21-.073-.261.1a4.38,4.38,0,0,0-.035,
            2.267,4.228,4.228,0,0,0,1.467,2.533,5.132,5.132,0,0,0,4.534,1.006,4.728,4.728,0,0,0,
            2.277-1.151A4.886,4.886,0,0,0-764.171-88.369Zm-10.238-4.652a.823.823,0,0,0-.115.323.8.8,
            0,0,1-.083.31.808.808,0,0,0-.038.9,3.125,3.125,0,0,0,1.574,1.653,9.865,9.865,0,0,0,
            5.438.987,6.391,6.391,0,0,0,3.908-1.95,1.673,1.673,0,0,0,
            .3-1.912c-.046-.1-.086-.256-.189-.269s-.089.161-.153.237a.208.208,0,0,0-.019.033,4.49,
            4.49,0,0,1-2.753,2.078,9.1,9.1,0,0,1-4.218.222,5,5,0,0,1-2.946-1.5A3.95,3.95,0,0,
            1-774.409-93.021Zm2.832.948a4.76,4.76,0,0,0,2.731,1.02,4.862,4.862,0,0,0,
            2.724-1.008c-.019.217-.159.331-.214.508a1.756,1.756,0,0,0,
            .889-.367c.272-.211.265-.411-.033-.575a6.048,6.048,0,0,0-1.439-.609,8.121,
            8.121,0,0,0-2.786-.195,6.384,6.384,0,0,0-2.526.813c-.273.154-.289.336-.049.542a1.815,
            1.815,0,0,0,.8.379c.033.007.077.022.106-.033A1.3,1.3,0,0,1-771.577-92.073Zm.719,
            4.106c0-.172,0-.344,0-.515,0-.076,0-.135-.092-.158a11.808,11.808,0,0,
            1-1.71-.536c-.087-.035-.132-.008-.142.087-.059.556-.135,1.111-.151,1.671a.622.622,0,0,0,
            .305.618,2.151,2.151,0,0,0,1.438.243.336.336,0,0,0,
            .3-.361C-770.911-87.269-770.852-87.617-770.857-87.967Zm2.264-7.244a2.052,2.052,0,0,
            0,.347,1.3.769.769,0,0,0,1.315-.021,2.025,2.025,0,0,0,.013-2.216.773.773,0,0,
            0-1.348-.012A1.772,1.772,0,0,0-768.593-95.212Zm-2.844.227a1.737,1.737,0,0,0,
            .257.949.8.8,0,0,0,1.465,0,1.984,1.984,0,0,0,.236-1.363,1.528,1.528,0,0,
            0-.433-.915.715.715,0,0,0-1.07,0A1.729,1.729,0,0,0-771.437-94.985Z"
            transform="translate(776.578 98.963)" :fill="!darkMode ? '#fff' : '#47040f'"/>
          <path id="Path_120" data-name="Path 120"
            d="M-730.4,260.3a1.348,1.348,0,0,1-.475.3,4.859,4.859,0,0,1-3.183.128,3.418,3.418,0,0,
            1-1.118-.479.793.793,0,0,1-.27-1.185c.025-.044.06-.083.029-.14a.305.305,0,0,1,.067-.352,
            1.815,1.815,0,0,1,.589-.447c.106-.061.146.039.2.094a7.172,7.172,0,0,0,2.478,1.685,6.93,
            6.93,0,0,0,1.558.366C-730.494,260.274-730.464,260.282-730.4,260.3Z"
            transform="translate(737.173 -244.415)" :fill="!darkMode ? '#fff' : '#47040f'"/>
          <path id="Path_121" data-name="Path 121"
            d="M-560.358,260.351c.367-.069.7-.12,1.027-.2a6.265,6.265,0,0,0,2.6-1.389,4.824,4.824,0,
            0,0,.568-.538c.051-.059.1-.1.179-.047a2.246,2.246,0,0,1,
            .51.366c.112.123.22.256.126.443-.028.056.019.1.043.139a.785.785,0,0,1-.261,1.158,3.386,
            3.386,0,0,1-1.133.491,4.843,4.843,0,0,1-3.2-.134,2.846,2.846,0,0,1-.334-.183A1.208,
            1.208,0,0,1-560.358,260.351Z" transform="translate(568.648 -244.466)"
            :fill="!darkMode ? '#fff' : '#47040f'"/>
          <path id="Path_122" data-name="Path 122"
            d="M-561.091-11.986c-.089.015-.156-.028-.165-.151a.339.339,0,0,
            0-.027-.091c-.022-.059-.04-.134-.116-.134s-.1.083-.123.148,0,
            .185-.112.22c-.189.058-.283-.018-.254-.216a.849.849,0,0,1,.213-.48.351.351,0,0,1,
            .5-.045.838.838,0,0,1,.279.644C-560.892-11.976-560.991-11.992-561.091-11.986Z"
            transform="translate(570.125 16.12)" :fill="!darkMode ? '#fff' : '#47040f'"/>
          <path id="Path_123" data-name="Path 123"
            d="M-618.756-12.547a.753.753,0,0,1,.144-.464.329.329,0,0,1,.561,0,.817.817,0,0,1,
            .027.9.342.342,0,0,1-.617-.012A.767.767,0,0,1-618.756-12.547Z"
            transform="translate(624.807 16.46)" :fill="!darkMode ? '#fff' : '#47040f'"/>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'QQ',
  props: {
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
