<template>
  <div class="contact">
    <Banner
      title="CONTACT"
      subtitle="聯絡我們"
      :img="bannerImg"
    />
    <div class="bg_bottom_wrapper">
      <img class="bg_bottom" src="@/assets/imgs/contact/bg_2.png" alt="">
      <div class="bg_space"></div>
    </div>

    <div class="container w1400">
      <!-- 項目 -->
      <div
        ref="wrapper"
        class="wrapper"
        v-for="(item, k) in contactInfo"
        :key="`item_${k}`">
        <!-- 背景 -->
        <div v-if="item.bg" class="bg">
          <img class="bg-img" src="@/assets/imgs/contact/bg.png" alt="">
        </div>
        <!-- 內容 -->
        <div class="content" :class="{ 'reverse' : k % 2 !== 0}">
          <!-- 地圖 -->
          <div class="col50" ref="content">
            <iframe :src="item.google"
              width="100%"
              :height="mapHeight"
              style="border:0;" allowfullscreen="" loading="lazy"></iframe>
          </div>

          <!-- 資訊 -->
          <div class="col50 info">
            <h4
              class="branch_name txt-main"
              :class="{ 'txt-white' : mainTitleWhite && k === 1 }">
              {{item.title}}<span class="suffix-redCube"></span>
            </h4>
            <h5 class="company_name" :class="`txt-bold txt-${item.darkMode ? 'white' : 'black'}`"
            >{{item.name}}</h5>
            <p class="txt-medium_gray eng_title">{{item.enName}}</p>
            <!-- 聯絡方式 -->
            <div
              :class="ik % 2 === 0 ? 'col60' : 'col40'"
              v-for="(info, ik) in item.information" :key="`info_${ik}`">
              <Component :is="info.icon" :darkMode="item.darkMode"/>
              <p :class="`txt-${item.darkMode ? 'white' : 'black'}`">{{info.value}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bannerImg from '@/assets/imgs/banner/banner_contact.png';
import Banner from '@/components/banner/Index.vue';
import Location from '@/components/icon/Location.vue';
import Phone from '@/components/icon/Phone.vue';
import Email from '@/components/icon/Email.vue';
import Fax from '@/components/icon/Fax.vue';
import QQ from '@/components/icon/QQ.vue';
import { contactInfo } from '@/lib/const';

export default {
  name: 'Contact',
  components: {
    Banner,
    Location,
    Phone,
    Email,
    Fax,
    QQ,
  },
  data() {
    return {
      bannerImg,
      contactInfo,
      mapHeight: '500px',
      mainTitleWhite: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.content[0].clientWidth && this.$refs.wrapper[0].clientWidth <= 960) {
        this.mapHeight = `${+this.$refs.content[0].clientWidth * (9 / 16)}px`;
        this.mainTitleWhite = true;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/helper/_variable.scss';
.contact {
  position: relative;

  .bg_bottom_wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-height: 500px;
  }

  .bg_bottom {
    position: relative;
    bottom: 0;
    width: 100%;
    max-height: 500px;
  }

  .bg_space {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: center/cover no-repeat;
    background-image: url('../../assets/imgs/contact/bg_2.png');
    background-attachment: fixed;
  }

  // .bg_bottom_wrapper {
  //   position: absolute;
  //   bottom: 0;
  //   left: 0;
  //   width: 100%;
  //   background-color: rgba(red, 0.5);
  //   .bg_bottom {
  //     width: 100%;
  //     max-height: 500px;
  //   }
  // }
  // .bg_bottom_decor {
  //   position: absolute;
  //   bottom: 0;
  //   width: 100%;
  //   padding-bottom: calc(650 / 1920 * 100%);
  //   background: center/cover no-repeat;
  //   background-image: url('../../assets/imgs/contact/bg_2.png');
  //   background-attachment: fixed;
  // }

  .wrapper {
    position: relative;
    padding-top: 10%;

    .bg {
      position: absolute;
      width: 90%;
      height: 75%;
      right: 0;
      bottom: 0;
      background-color: $color-light_gray;
      z-index: -1;

      .bg-img {
        position: absolute;
        right: 0;
        bottom: 0;
        max-width: 405;
        height: auto;
      }
    }

    .content {
      display: flex;

      &.reverse {
        flex-direction: row-reverse;
      }
    }

    &:last-child {
      padding-bottom:4%;
    }
  }

  .info {
    padding: 5%;

    &>div {
      padding-bottom: 5%;
    }

    h4 {
      padding-bottom: 18%;
    }

    .eng_title {
      padding-bottom: 8%;
    }
  }

  @media screen and (max-width: 1200px) {
    .bg_bottom_wrapper {
      max-height: initial;
      // height: 900px;
      height: 32%;
    }
    .bg_bottom {
      max-height: initial;
      // height: 900px;
      height: 100%;
    }

    .wrapper {
      .bg {
        width: 100%;
      }

      .content {
        flex-direction: column !important;
        &>.col50 {
          width: 100%;
        }
      }

      &:last-child {
        padding-bottom:8%;
      }
    }

    .info {
      .branch_name {
        padding-bottom: 1.25rem;
        font-size: 1.3rem;
      }
      .company_name {
        font-size: 1.3re;
      }
    }
  }
  @media screen and (max-width: 425px) {
    .info {
      .col60, .col40 {
        display: flex;
        width: 100%;
        align-items: center;

        p {
          padding-left: 3%;
        }
      }
    }
  }
}
</style>
